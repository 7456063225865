import { useRef, useEffect, useState, useCallback } from 'react';
import createPersistedState from 'use-persisted-state';
import { useHistory, useLocation } from 'react-router-dom';

import { env } from '../env';

import { executiveLogout, validateToken } from '../services/authRepository';
import { isObjectEmpty } from './utils';
import publishKeys from './publishKeys';

export const getLocalStorageValue = key =>
  JSON.parse(localStorage.getItem(key));

export const createUseLogin = (key = 'loginData') => {
  const usePersistedLogin = createPersistedState(key);
  const useSessionLogin = createPersistedState(
    `session-${key}`,
    sessionStorage,
  );

  return () => {
    const [loginData, setLoginData] = usePersistedLogin({});
    const [sessionData, setSessionData] = useSessionLogin({});

    useEffect(() => {
      const getSessionData = async () => {
        try {
          const data = await validateToken(loginData.token, loginData.clientId);
          setSessionData(data);
          localStorage.setItem(
            'incodeRefreshToken',
            loginData.incodeRefreshToken,
          );
        } catch {
          setLoginData({});
        }
      };
      if (loginData?.token && isObjectEmpty(sessionData)) {
        getSessionData().catch(err => {
          console.log('Error getting session data:', err); // eslint-disable-line no-console
        });
      }
    }, [
      loginData.token,
      loginData.clientId,
      sessionData,
      setLoginData,
      setSessionData,
      loginData.incodeRefreshToken,
    ]);

    function login(data) {
      setLoginData({
        token: data.token,
        clientId: data?.clientId,
        incodeRefreshToken: data?.incodeRefreshToken,
      });
    }

    function changeOrg(organization) {
      setSessionData(ld => ({ ...ld, organization }));
    }

    async function logout() {
      if (loginData?.token) {
        await executiveLogout(loginData.token);
      }
      setLoginData({});
      setSessionData({});
    }

    const logoutOnEmptyToken = () => {
      const currentLoginData = JSON.parse(localStorage.getItem(key) || '{}');
      if (!currentLoginData.token) {
        setLoginData({});
        setSessionData({});
      }
    };

    useEventListener('storage', logoutOnEmptyToken, window);

    return {
      isLogged: !!loginData?.token,
      validatedUser: !isObjectEmpty(sessionData),
      login,
      logout,
      changeOrg,
      setLoginData,
      ...loginData,
      ...sessionData,
    };
  };
};
const safeUrl = [
  'manualCorrection',
  'users',
  'manualCorrectors',
  'dashboard',
  'log-in',
];

const urlParams = window.location.pathname.split('/');
export const clientName =
  urlParams[1] && !safeUrl.includes(urlParams[1]) ? urlParams[1] : '';
export const loginStorageKey = 'IncodeLoginData';
export const manualCorrectionStorageKey = `${clientName}ManualCorrectionLoginData`;
export const adminCorrectionStorageKey = `${clientName}AdminCorrectionLoginData`;

export const useLogin = createUseLogin(loginStorageKey);
export const useManualCorrectionLogin = createUseLogin(
  manualCorrectionStorageKey,
);
export const useAdminCorrectionLogin = createUseLogin(
  adminCorrectionStorageKey,
);

export const invalidTokenLogout = (status, path) => {
  const { userType } =
    JSON.parse(sessionStorage.getItem(loginStorageKey)) || {};

  const clearStorage = key => {
    localStorage.setItem(key, '{}');
    sessionStorage.setItem(`session-${key}`, '{}');
    window.dispatchEvent(new Event('storage'));
  };

  if (path.toLowerCase().includes('manualcorrectors')) {
    clearStorage(adminCorrectionStorageKey);
    return;
  }

  if (path.toLowerCase().includes('manualcorrection')) {
    clearStorage(manualCorrectionStorageKey);
    return;
  }

  if (userType !== 'INCODE_ADMIN' && status !== 403) {
    clearStorage(loginStorageKey);
  }
};

export const getClientId = () => {
  const path = window.location.pathname;

  if (path.toLowerCase().includes('manualcorrectors')) {
    const { clientId: mcClientId } =
      JSON.parse(
        sessionStorage.getItem(`session-${adminCorrectionStorageKey}`),
      ) || {};

    return clientName || mcClientId || 'incode';
  }

  if (path.toLowerCase().includes('manualcorrection')) {
    const { clientId: mcClientId } =
      JSON.parse(
        sessionStorage.getItem(`session-${manualCorrectionStorageKey}`),
      ) || {};

    return clientName || mcClientId;
  }

  const { clientId } = JSON.parse(localStorage.getItem(loginStorageKey)) || {};
  return clientId || 'incode';
};

export const useEventListener = (eventName, handler, element = global) => {
  const savedHandler = useRef();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element?.addEventListener;
    if (!isSupported) return;
    const eventListener = event => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useIsMountedRef() {
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMountedRef;
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export const useQueryState = (key, initialValue) => {
  const query = useQuery();
  const history = useHistory();
  const [value, _setValue] = useState(query.get(key) || initialValue);
  const setValue = useCallback(
    newValue => {
      _setValue(newValue);
      query.set(key, newValue);
      history.replace({ search: query.toString() });
    },
    [history, key, query],
  );

  return [value, setValue];
};

const getInitialState = (initialObject, query) => {
  const initialState = {};

  for (const [key, initialVal] of Object.entries(initialObject)) {
    const finalValue = query.get(key) || initialVal;
    initialState[key] = finalValue;
  }

  return initialState;
};

export const useQueryObjState = initialValue => {
  const query = useQuery();
  const history = useHistory();
  const [value, _setValue] = useState(getInitialState(initialValue, query)); // set for each entry based on key
  const setValue = useCallback(
    (key, newValue) => {
      _setValue(previousValues => ({ ...previousValues, [key]: newValue }));
      newValue === '' ? query.delete(key) : query.set(key, newValue);
      history.replace({ search: query.toString() });
    },
    [history, query],
  );

  return [value, setValue];
};

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useEncryption({ token, isLogged }) {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (isReady) return;

    async function sendKeys() {
      if (env.REACT_APP_ENCRYPT !== 'true') {
        setIsReady(true);
        return;
      }

      if (isLogged) {
        await publishKeys(token);
        setIsReady(true);
      }
    }

    sendKeys();
  }, [token, isLogged, isReady]);

  return isReady;
}

export function useTimeout(callback, timeout) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (timeout === null) {
      return;
    }
    const id = setTimeout(() => {
      savedCallback.current();
    }, timeout);

    return () => clearTimeout(id);
  }, [timeout]);
}
